import React from "react"
import { graphql, Link } from "gatsby"

import { Layout } from "../components/common"
import { MetaData } from "../components/common/meta"

const State = ({ data, location }) => {
    const { state } = data.state

    return (
        <>
            <MetaData 
                location={location} 
                title={`${state} Go Karting Cities`}
                description={`Find go karting tracks near ${state}. Motorsport tracks and events.`}
                />
            <Layout>
                <h1>Popular Cities in {state}</h1>
                <ul>
                    {data.cities.edges.map(({ node }) => (
                        <li>
                            <a href={`/${node.filename}`}>{node.city}</a>
                        </li>
                    ))}
                </ul>
            </Layout>
        </>
    )
}
export default State

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query StateQuery($state: String!) {
        state: worldcitiesFilteredCsv(admin_name: { eq: $state }) {
            filename
            city
            lat
            lng
            city_ascii
            state: admin_name
        }
        cities: allWorldcitiesFilteredCsv(
            filter: { admin_name: { eq: $state } }
        ) {
            edges {
                node {
                    filename
                    city
                }
            }
        }
    }
`
